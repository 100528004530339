import React from "react";
import { navigate } from "gatsby";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderTop from "@/components/tickets/slider-top";
import TabContent from "@/components/tickets/tab-content";
import "@/css/passes-tickets.css";

const Tickets = () => {
  if (typeof window !== "undefined") {
    navigate("https://bilety.jagiellonia.pl/", { replace: true });
  }

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Bilety">
          <HeaderOneTop />
          <HeaderOne />
          <SliderTop />
          <TabContent />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Tickets;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";

import bgPasses from "@/images/bg/passes/top.jpg";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTop = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
  };
  return (
    <section className="main-slider slider-subpages">
      <Swiper {...mainSlideOptions}>
        <SwiperSlide>
          <div
            className="image-layer"
            style={{ backgroundImage: `url('${bgPasses}')` }}
          ></div>
          <Container className="w-auto animated fadeInUp pt-0">
            <Row className="passes-slider-heading">
              <Col lg={12} className="text-center">
                <h3 className="main-slider__title mb-3">BILETY</h3>
                <h4 className="text-white">SEZON 2022/23</h4>
              </Col>
            </Row>
          </Container>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default SliderTop;
